const MICROAPP_VERSION = "1.9.0"

const MICROAPPS_URL = {
  latest: `https://microapps.pf-labs.net/latest/peoplefluent.loader.js`,
  production: `https://microapps.peoplefluent.com/versions/${MICROAPP_VERSION}/peoplefluent.loader.js`,
}

/**
 * Mount the widget via scripts at a specified DOM element
 * @param {Element} element DOM element to mount at
 * @param {{microapp:string, id:string}} config Microapp configuration
 */
export function mountMicroappScript(element, config) {
  const script = document.createElement("script")
  script.src = MICROAPPS_URL.latest
  script.async = true
  Object.keys(config).forEach(attr => {
    script.dataset[attr] = config[attr]
  })
  element.appendChild(script)
}

/**
 * Gracefully unmount a microapp freeing all resources
 * @param {string} id Microapp id as passed in its config
 */
export function unmountMicroapp(id) {
  try {
    window.peoplefluent.muapps.apps[id].destroy()
  } catch (ex) {
    // do nothing for now
    // an exception is usually due to:
    //  - an incorrect id being passed
    //  - trying to unmount before the app was successfully mounted
  }
}
