import React, { Component, createRef } from "react"
import { graphql, StaticQuery } from "gatsby"
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import Wrapper from "../components/layout/wrapper/wrapper"
import HeroSimple from "../components/hero/heroSimple/heroSimple"
import BlockContent from "../components/blockContent/blockContent"
import { mountMicroappScript, unmountMicroapp } from "../helpers/breezy"

// MIGRATED FROM PF

import "./careers.scss"

const CAREERS_APP_CONFIG = {
  microapp: "job-board",
  id: "site-careers",
  datapath: "https://ltg.breezy.hr/json",
  department: "Gomo",
}

class CareersTemplate extends Component {
  constructor(props) {
    super(props)
    this.careersContainer = createRef()
  }

  componentDidMount() {
    mountMicroappScript(this.careersContainer.current, {
      ...CAREERS_APP_CONFIG,
    })
  }

  componentWillUnmount() {
    unmountMicroapp(CAREERS_APP_CONFIG.id)
  }

  render(props) {
    const { seo, content } = this.props.sanityCareers?.tabs
    return (
      <Layout>
        <SEO {...seo} />
        <HeroSimple blocks={content?.hero[0].children} subtitle={content?.subtitle} />
        <div className="careers-container" id="site-careers" ref={this.careersContainer} />
        <Wrapper background="blue">
          <div className="grid">
            <div className="gridItem gridItemCenter large-9">
              <BlockContent blocks={content?.richText} />
            </div>
          </div>
        </Wrapper>
      </Layout>
    )
  }
}

const Careers = () => (
  <StaticQuery
    query={graphql`
      query {
        sanityCareers {
          tabs {
            seo {
              canonical
              description
              metaTitle
              noIndex
              openGraphImage {
                asset {
                  url
                }
              }
              removeSitemap
            }
            content {
              subtitle
              hero: _rawHero
              richText: _rawRichText(resolveReferences: { maxDepth: 5 })
            }
          }
        }
      }
    `}
    render={data => <CareersTemplate {...data} />}
  />
)

export default Careers
